<template>
  <div class="login-screen">
    <FormUserPasswordReset
      v-if="token && user_id"
      :token="token"
      :user_id="user_id"
      :invite="invite"
    />
    <FormUserPasswordRequestLink v-else />
  </div>
</template>

<script>
// @ is an alias to /src
import FormUserPasswordReset from "@/components/form-user-password-reset.vue";
import FormUserPasswordRequestLink from "@/components/form-user-password-request-link.vue";

export default {
  name: "SignUp",
  props: {
    token: {
      type: String,
      required: false,
      default: () => ""
    },
    user_id: {
      type: String,
      required: false,
      default: () => ""
    },
    invite: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    FormUserPasswordReset,
    FormUserPasswordRequestLink
  },
  created: function() {
    let self = this;
    self.$store.dispatch("user/logout");
  },
  mounted: function() {
    this.$store.dispatch("reset");
  }
};
</script>

<style scoped>
.login-screen {
  background: #d2d6de;
  background: rgb(209, 227, 226);
  background: linear-gradient(
    180deg,
    rgba(209, 227, 226, 1) 0%,
    rgba(154, 171, 175, 1) 67%
  );
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  box-shadow: 0px 0px 6px grey;
  overflow: hidden;
}
</style>
